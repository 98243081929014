<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Determine the balanced equation for the <b>precipitation</b> reaction resulting from mixing
        the pairs of ionic compounds shown below:
      </p>

      <p class="mb-4">
        a) Aqueous sodium carbonate reacts with aqueous copper(II) sulfate
        <!--        sodium carbonate and silver nitrate -> silver carbonate precips-->
        <!--        sodium chloride and silver nitrate -> silver chloride precips-->
      </p>
      <chemical-notation-input
        v-model="inputs.reactionA"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-4">
        b) Aqueous copper(II) sulfate reacts with aqueous barium chloride
        <!--        sodium sulfate + barium chloride-->
        <!--        lead nitrate + sodium chromate: lead chromate precips-->
        <!--        calcium nitrate + sodium carbonate: calcium carbonate precips-->
        <!--        PbCl2 insoluble-->
        <!--        FeS insoluble-->
        <!--        CuS insoluble-->
        <!--        CaF2 insoluble-->
        <!--        Fe(OH)3 insoluble-->
      </p>

      <chemical-notation-input
        v-model="inputs.reactionB"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question225',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        reactionA: null,
        reactionB: null,
      },
    };
  },
};
</script>
